$(() => {
    const selector = '.fadeMeIn';

    animateOnScroll();
    animate(selector, '.animated', 'fadeIn animated');

    function animateOnScroll() {
        $(window).scroll(function () {
            animate(selector, '.animated', 'fadeIn animated');
        });
    }

    function animate(selector: string, notSelector: string, classes: string) {
        $(selector).not(notSelector).each(function () {
            if (isScrolledIntoView(this)) {
                $(this).addClass(classes);
                $(this).css({ opacity: 1 });
            }
        });
    }

    function isScrolledIntoView(elem: any) {
        const topOfElement = $(elem).offset().top;
        const bottomOfElement = $(elem).offset().top + $(elem).outerHeight();
        const bottomOfScreen = $(window).scrollTop() + $(window).innerHeight();
        const topOfScreen = $(window).scrollTop();
        return (bottomOfScreen > topOfElement) && (topOfScreen < bottomOfElement);
    }
});